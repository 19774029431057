//还款方式 选择框
<template>
  <div class="repaymentPlaItem" v-bind="$attrs" v-on="$listeners">
    <div class="showValue">{{couvalue}}</div>
    <el-tooltip  effect="dark"  :enterable="false" :content="'设置还款计划'" placement="top"  :hide-after='3000'>
      <span class="set iconfont iconshezhi" @click="visible = true" ></span>
    </el-tooltip>
    <!-- 还款登记弹窗 -->
    <base-dialog
      :visible="visible"
      width="1200px"
      :showFooter="false"
      title="还款计划"
       :before-close="close"
    >
      <base-table
         :columns="repaymentPlanColumn"
        :tableAttrs="{
          data: repaymentPlanData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
       <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </base-table>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
// import BaseInput from '@/components/input/sz-input/sz-input.vue'
// import AmountInput from '@/components/input/amount-input/amount-input.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
// import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists, getDict } from '@/filters/fromDict'
import { repaymentPlanTable } from '../utils/closed-loop-info-config'
// import { contactApi } from '@/api/contactApi'
import { formatDate } from '@/utils/auth/common'
import { contactApi } from '@/api/contactApi'

export default {
  props: {
    // contactListOptions: Array
  },
  components: {
    BaseDialog,
    baseButton,
    baseTable
  },
  data () {
    return {
      visible: false,
      curSelected: '', // 选中的值
      repaymentPlanData: [], // 还款计划表数据,
      startDatePicker: {},
      contactListOptions: [] // 合同选择组
    }
  },
  computed: {
    options () {
      return getDictLists('REPAY_TYPE_STATUS')
    },
    repaymentPlanColumn () {
      return repaymentPlanTable(this)
    },
    couvalue () {
      return this.$attrs.value ? getDict('REPAY_TYPE_STATUS', this.$attrs.value.toString()) : '--'
    }

  },
  created () {
    console.log(this.$attrs)
    this.businessId = this.$route.query.businessId
    this.startDatePicker = {
      disabledDate (time) {
        const disabledTime = new Date('1999-12-31')
        return time.getTime() <= disabledTime
      }
    }
  },
  mounted () {
    if (this.businessId) {
      this.getContactlList()
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.getContactlList()
        if (this.$attrs.formData && this.$attrs.formData.length > 0) {
          this.repaymentPlanData = this.$attrs.formData
        }
      }
    }
  },
  methods: {
    close () {
      this.visible = false
    },
    // 点击提交，把数据加到外部对象上
    submit () {
      this.$emit('submit', this.repaymentPlanData)
      this.visible = false
    },
    change (data) {},
    // 删除计划
    delPlan (data, index) {
      this.repaymentPlanData.splice(index, 1)
    },
    // 增加计划
    addPlanItem () {
      this.repaymentPlanData.push({})
    },
    // 获取还款计划合同列表
    getContactlList () {
      contactApi
        .getContactlList({ businessId: this.businessId })
        .then((res) => {
          if (res.data.length > 0) {
            this.contactListOptions = res.data.map((item) => {
              return {
                repayContractName: item.finContractName,
                keyId: item.keyId
              }
            })
          }
        })
    },
    // 选择合同自动拼接
    changeContract (data, index) {
      console.log(data, '++==')
      let value = ''
      const repaymentPlanContractArr = []
      const repayContractNameArr = []
      data.forEach((elem) => {
        // elem 合同id
        this.contactListOptions.forEach((item) => {
          if (item.keyId === elem) {
            if (repaymentPlanContractArr.length === 0) {
              repayContractNameArr.push(item.repayContractName)
              repaymentPlanContractArr.push(
                this.repaymentPlanData[index].isDate
                  ? this.repaymentPlanData[index].preRepayDate +
                      '/' +
                      item.repayContractName
                  : formatDate(
                    this.repaymentPlanData[index].preRepayDate,
                    'YY/MM/DD'
                  ) +
                      '/' +
                      item.repayContractName
              )
            } else {
              repaymentPlanContractArr.push(item.repayContractName)
            }
          }
        })
      })

      value = repaymentPlanContractArr.join(',')
      this.$set(this.repaymentPlanData[index], 'repayContractName', repayContractNameArr.join(','))
      this.$set(
        this.repaymentPlanData[index],
        'repayContractId',
        data.join(',')
      )
      this.$set(this.repaymentPlanData[index], 'repayCondition', value)
    },
    // 选择时间
    changeDate (data, index) {
      console.log(data, index)
      if (data) {
        this.$set(
          this.repaymentPlanData[index],
          'repayCondition',
          formatDate(this.repaymentPlanData[index].preRepayDate, 'YY/MM/DD') +
            '/'
        )
      } else {
        this.$set(this.repaymentPlanData[index], 'repayCondition', '')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-input__validateIcon {
  display: none;
}
.repaymentPlaItem {
  display: flex;
}
.add {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .addbutton {
    margin-left: 20px;
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
// /deep/ .el-select {
//   width: 240px !important;
// }
.showBtn {
  width: calc(100% - 50px) !important;
}
.set {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
  background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
  border-radius: 50%;
}
/deep/ .el-select__tags {
  overflow-x: hidden;
}
.showValue{
  margin-top: 10px;
}
</style>
